import { Cluster, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { Point } from 'ol/geom';
import { getCenter } from "ol/extent";
import Vector from "ol/layer/Vector"
import { Fill, Stroke, Text, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import Feature from 'ol/Feature.js';

import { getFeatureType } from "../../commons/geoSpatialFunctions"
import { isMobile } from 'react-device-detect';

const CLUSTER_DISTANCE = 100
const CLUSTER_MIN_DISTANCE = 50
const DEFAULT_STROKE_COLOR = "#000000";
const DEFAULT_STROKE_WIDTH = 0.5;
const DEFAULT_FILL_COLOR = '#3399CC';
const DEFAULT_FILL_OPACITY = 0.8;
const BREAKPOINT_ZOOM = isMobile ? 16.2 : 15.0; // mayor valor es más zoom
const STROKE_MULTIPLY = 6;
const CLUSTER_CIRCLE_SIZE = 24;
var highlightedFeature = null;

export { BREAKPOINT_ZOOM }
export function updateLayerStyle(map, vectorLayer, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, breakpoint_zoom = BREAKPOINT_ZOOM, cluster_distance = CLUSTER_DISTANCE, cluster_min_distance = CLUSTER_MIN_DISTANCE) {
    let numberOfFeatures = 0;
    const thereIsVectorLayer = vectorLayer && vectorLayer != [] && vectorLayer != false && vectorLayer.length > 0 && map != null && map != undefined;
    const zoom = map?.getView().getZoom() ?? 20;
    numberOfFeatures = getNumberOfVisibleFeatures(map);
    if (thereIsVectorLayer) {
        
        let originalSource = vectorLayer[0].getSource();

        // Si en algún momento esa fuente es un cluster, la “verdadera” fuente estará dentro.
        if (originalSource instanceof Cluster) {
        originalSource = originalSource.getSource();
        }

        const currentClusterSource = vectorLayer[0].getSource();
       // console.log("currentClusterSource",zoom < breakpoint_zoom , currentClusterSource instanceof Cluster)
        if (zoom < breakpoint_zoom ){ //&& currentClusterSource instanceof Cluster) {
            // Asegurarse de que el source actual es un ClusterSource
            if(currentClusterSource instanceof Cluster){
                vectorLayer[0].setSource(createClusterSource(currentClusterSource.getSource(), cluster_distance, cluster_min_distance))
            }else{

                vectorLayer[0].setSource(createClusterSource(currentClusterSource, cluster_distance, cluster_min_distance))

            }
        } else if (currentClusterSource instanceof Cluster) {
           // vectorLayer[0].setSource(createClusterSource(currentClusterSource.getSource(), 0, 0));
            vectorLayer[0].setSource(originalSource);
        }
        
    }

   // console.log("UpdateLayerStyle", vectorLayer,thereIsVectorLayer, vectorLayer[0]?"true":"false")
    vectorLayer[0]?.setStyle((feature) => {
        //console.log("Dentro de stile funcion", feature)
        return styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures, breakpoint_zoom)

    });
   // console.log("Longitud de vectorLayer", vectorLayer.length)
    // if(vectorLayer[1]){
    //     console.log("UpdateLayerStyle1", vectorLayer,thereIsVectorLayer, vectorLayer[1]?"true":"false")

    //     const currentClusterSourcePoints = vectorLayer[1].getSource();
    //     if (zoom < breakpoint_zoom && currentClusterSourcePoints instanceof Cluster) {
    //         // Asegurarse de que el source actual es un ClusterSource
    //         vectorLayer[1].setSource(createClusterSource(currentClusterSourcePoints.getSource(), cluster_distance, cluster_min_distance))
    //     } else if (currentClusterSourcePoints instanceof Cluster) {
    //         vectorLayer[1].setSource(createClusterSource(currentClusterSourcePoints.getSource(), 0, 0));
    //     }
    //     vectorLayer[1]?.setStyle(feature => {
    //         //console.log("Dentro de stile funcion", feature)
    //         return styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures)
    
    //     });
    // }
   
}

// Función para crear la fuente del clúster
function createClusterSource(source, distance = 100, minDistance = 5) {
    return new Cluster({
        distance: distance,
        minDistance: minDistance,
        source: source,
        geometryFunction: feature => {
           // const type = getFeatureType(feature)
           
            try {
                return new Point(getCenter(feature.getGeometry().getExtent()))
               
                
            } catch {
         
                    
                    return feature.getGeometry();
                
            }
        }
    
    });
}

function descomponerMultipoligonos(source) {
    try{
        const features = source.getFeatures();
    const nuevasFeatures = [];
    features.forEach(feature => {
      const geom = feature.getGeometry();
      const properties = feature.getProperties();
      delete properties.geometry;  
      if (geom.getType() === 'MultiPolygon') {
        const poligonos = geom.getPolygons();
        poligonos.forEach(poligono => {
            //const nuevaFeature = new Feature({geometry:poligono, ...properties});
            const nuevaFeature = new Feature(poligono);
            nuevaFeature.setProperties(properties)
            nuevasFeatures.push(nuevaFeature);
        });
      } else {
        nuevasFeatures.push(feature);
      }
    });
    // Reemplazar las características antiguas por las nuevas descompuestas
    source.clear();
    source.addFeatures(nuevasFeatures);
    return source;
    }catch(error){
        return source
    }
    
  }
// Función principal para obtener la capa vectorial
export function getVectorLayerFromSources(vectorLayers, vectorSources, allGeometries, strokeColor, strokeWidth, fillColor, fillOpacity, map, getBgColorFromFeature) {
   // console.log("getVectorLayerFromSources allGeometries", allGeometries.length)
    let source = new VectorSource({ features: allGeometries });
    source = descomponerMultipoligonos(source);
    const clusterSource = createClusterSource(source);
    let numberOfFeatures = 0;
    if (map != null && map != undefined) {
        numberOfFeatures = getNumberOfVisibleFeatures(map);
    }
    //console.log("number of features", numberOfFeatures)
    const clusters = new VectorLayer({
        source: clusterSource,
        style: (feature) => styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures)
    });
    // const individual = new VectorLayer({
    //     source: source,
    //     style: (feature) => styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures)
    // });
    return [clusters];
}
function isCluster(feature) {
    return Array.isArray(feature.get('features'));
}

export function handleMapPointerMove(map, e, layer, tooltip, tooltipRef, strokeColor, strokeHoverColor, strokeWidth, fillColor, fillPolygonsOpacity, fillPointsOpacity, getBgColorFromFeature = false, isGetCoordinatesOnClickEnabled = false) {
    const view = map.getView();
    const currentZoom = view.getZoom();
    if (currentZoom < layer.breakpoint_zoom || isGetCoordinatesOnClickEnabled) {
        return true;
    }
    const pixel = map.getEventPixel(e.originalEvent);
    const hit = map.hasFeatureAtPixel(pixel);
    map.getTargetElement().style.cursor = hit ? "pointer" : "";

    const feature = map.forEachFeatureAtPixel(pixel, function (feature) {
        if (!isCluster(feature)) {
           return feature; // Retorna solo si la feature no es un clúster
        }else{
           
            if(feature.get("features").length == 1) {
                return feature

            }else{
                let skip = 0
                for(const f in feature.get("features")){
                    
                    if(skip === 0){
                        //omito
                    }else{
                        try{
                            delete feature["features"][f]
                        }catch(e){
                            console.log(feature["features"])
                            // omito
                        }
                        

                    }
                    skip += 1;
                }
            }
            console.log("No puedo hacer mucho con esto", feature, Object.prototype.toString.call(            feature));
            // try{
            //     return FeatureCollection({"features": [feature.get('features')[0]]})
            // }catch (error){
            //     return feature
            // }
        }
    });
    
    // const feature = map.forEachFeatureAtPixel(pixel, (clusteredFeature) => {
    //     // Comprueba si la feature es un clúster
    //     const features = clusteredFeature.get('features');
    //     const coordinate = map.getCoordinateFromPixel(pixel);
    //     console.log("Check if cluster", pixel, coordinate)
    //     if (features && Array.isArray(features)) {
    //       // Es un clúster, revisa cada feature individualmente
    //       console.log("Revisando features", features)
    //       for (const feature of features) {
            
    //         const geometry = feature.getGeometry();
    //         console.log("Geometry", geometry, geometry.intersectsCoordinate(coordinate), coordinate )
    //         if (geometry && geometry.intersectsCoordinate(coordinate)) {
    //           // Devuelve la primera feature cuyo contorno contiene la coordenada
    //           console.log("Regresando primera geometría")
    //           return clusteredFeature
    //         }
    //       }
    //     } else {
    //       // No es un clúster, comprueba si la geometría de la feature contiene la coordenada
    //       console.log("No es un cluster")
    //       const geometry = clusteredFeature.getGeometry();
    //       if (geometry && geometry.intersectsCoordinate(pixel)) {
    //         return clusteredFeature;
    //       }
    //     }
    //     return null; // Ninguna feature contiene el píxel
    //   });
    if (highlightedFeature != feature && highlightedFeature != null && highlightedFeature != undefined && highlightedFeature != false) {
        const geometryType = getFeatureType(highlightedFeature);
        let style;
        if (geometryType != "Point") {
            style = layer.createGeometryStyle(strokeColor, strokeWidth, fillColor, fillPolygonsOpacity, highlightedFeature, getBgColorFromFeature, false);
        } else {
            //const features =  highlightedFeature.get('features');
            //const size = features.length;
            //style = createClusterStyle(feature, size, strokeColor, strokeWidth, fillColor, fillPointsOpacity, getBgColorFromFeature);

        }
        highlightedFeature.setStyle(style);
        highlightedFeature = null;
    }
    if (feature && getBgColorFromFeature != false) {
        const geometryType = getFeatureType(feature);
        console.log("Geometry type ", geometryType);
        if (geometryType != "Point") {
            if (highlightedFeature != feature ) {

            console.log("Resaltando feature - ",feature)
            const highlightStyle = layer.createGeometryStyle(strokeHoverColor, strokeWidth, fillColor, fillPolygonsOpacity, feature, getBgColorFromFeature, true);
            if (feature) {
                feature.setStyle(highlightStyle);
                highlightedFeature = feature;
            }
        }
        }

    }


    // Tooltip info disabled
    const TOOLTIP_DEFAULT_TEXT = "No hay datos para esta geometría";
    const tooltipIsEnabled = true;
    if (feature && tooltipIsEnabled) {
        let type = getFeatureType(feature);
        //if(type == "Point"){
        let tooltipText = feature.get("tooltip");
        if (layer.getTooltip != false) {
            tooltipText = layer.getTooltip(feature);
        }
        // Recupera el tooltip asociado a la feature
        if (!tooltipText) {
            tooltipText = TOOLTIP_DEFAULT_TEXT;
        }
        tooltipRef.current.innerHTML = tooltipText;
        tooltip.setPosition(e.coordinate);
        // }

    } else {
        tooltipRef.current.innerHTML = "";
        tooltip.setPosition(undefined);
    }
}
function getNumberOfVisibleFeatures(map) {
    let count = 0;
    const extent = map.getView().calculateExtent(map.getSize());
    if (map != undefined && map.getLayers() != undefined) {
        const layers = map.getLayers().getArray();
        if (layers.length > 1) {
            const vectorLayer = layers[1]; // Suponiendo que tu VectorLayer está en esta posición
            if (vectorLayer instanceof Vector) {
                const vectorSource = vectorLayer.getSource();
                if (vectorSource.getFeatures().length > 0) {
                    // Tu lógica aquí
                    vectorSource.forEachFeature((feature) => {
                        if (feature.getGeometry().intersectsExtent(extent)) {
                            count++;
                        }
                    });
                }
            }
        }
        if (layers.length > 2) {
            const vectorLayer = layers[2]; // Suponiendo que tu VectorLayer está en esta posición
            if (vectorLayer instanceof Vector) {
                const vectorSource = vectorLayer.getSource();
                if (vectorSource.getFeatures().length > 0) {
                    // Tu lógica aquí
                    vectorSource.forEachFeature((feature) => {
                        if (feature.getGeometry().intersectsExtent(extent)) {
                            count++;
                        }
                    });
                }
            }
        }
    }
    return count;
}

// Función de estilo
function styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures, breakpoint_zoom = BREAKPOINT_ZOOM) {
    //console.log("styleFunction feature", feature)


    const zoom = map?.getView().getZoom() ?? 20;
    let breakpointZoomOut = zoom <= breakpoint_zoom;
    let breakpointNumberOfFeatures = numberOfFeatures <= 1500
    const geometryType = getFeatureType(feature);
    //console.log("Breakpoint", geometryType, breakpointZoomOut, feature)

    if (!breakpointZoomOut && geometryType != "Point") {
        return createGeometryStyle(strokeColor, strokeWidth, fillColor, fillOpacity, feature, getBgColorFromFeature);
    } else {
        let size = 0;
        
        try{
            if("features" in feature){
                const features = feature.get('features');
                size = features.length;
            }else if("values_" in feature && "features" in feature.values_){
                size = feature.values_.features.length
            }else{
                size = 0;
            }
            
        }catch{
            size = 0;
        }
       
        return createClusterStyle(feature, size, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature);
    }
}


// Estilo para geometrías (zoom < 10)
export function createGeometryStyle(strokeColor, strokeWidth, fillColor, fillOpacity, feature, getBgColorFromFeature, isHigtlighted = false) {
    //console.log("Aquí createGeometryStyle", feature)
    try {
        if (!feature) {
            return
        }
        const bgColor = getBgColorFromFeature(feature);
        // console.log("getBgColorFromFeature", bgColor, `rgba(${bgColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})`);
        let backgroundColor = bgColor ? `rgba(${bgColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})` : fillColor ? `rgba(${fillColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})` : DEFAULT_FILL_COLOR;
        // console.log("backgroundColor2", backgroundColor);
        //if(feature.getGeometry())
        let style = new Style({
            stroke: new Stroke({
                color: strokeColor || DEFAULT_STROKE_COLOR,
                width: isHigtlighted ? (strokeWidth * STROKE_MULTIPLY || DEFAULT_STROKE_WIDTH * STROKE_MULTIPLY) : strokeWidth || DEFAULT_STROKE_WIDTH
            }),
            fill: new Fill({
                color: backgroundColor
            })
        });
        // var features = feature.get('features');
        // //console.log("Features", features)
        // if (features && features.length && features.length > 1) {
        //     //console.log("MAS DE UNA FEATURE", features)
        //     let result = []
        //     for (let element of features) {
        //         let styleCopy = style.clone();
        //         styleCopy.setGeometry(element.getGeometry());
        //         result.push(styleCopy)
        //     }
        //     //console.log("Result", result)
        //     return result;
        // }
        let result = []
        let styleCopy = style.clone();
        styleCopy.setGeometry(feature.getGeometry());
        result.push(styleCopy)
        return result
        //style.setGeometry(features[0].getGeometry());
        //return style;
    } catch (e) {
        console.error("Problema con los estilos", e)
        return
    }

}
// Estilo para clústeres (zoom >= 10)
function createClusterStyle(feature, size, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature) {
    console.log("createClusterStyle", feature)
    const bgColor = getBgColorFromFeature(feature);
    // console.log("getBgColorFromFeature", bgColor, `rgba(${bgColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})`);
    let backgroundColor = size > 1 ? "#FCFCFC" : bgColor ? `rgba(${bgColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})` : fillColor ? `rgba(${fillColor || DEFAULT_FILL_COLOR}, ${fillOpacity || DEFAULT_FILL_OPACITY})` : DEFAULT_FILL_COLOR;
    return new Style({
        image: new CircleStyle({
            radius: CLUSTER_CIRCLE_SIZE,
            stroke: new Stroke({
                color: strokeColor || DEFAULT_STROKE_COLOR,
                width: strokeWidth || DEFAULT_STROKE_WIDTH
            }),
            fill: new Fill({ color: backgroundColor })
        }),
        text: new Text({
            text: size > 1 ? size.toString() : '',
            font: "14px Arial",
            fill: new Fill({
                color: 'rgba(0, 0, 0, 1)'
            })
        })
    });
}

export function getBgColorFromFeatureGeneric(allPolygons, index, getBgColorFromFeature) {
    let bgColor = false;
    let feature = allPolygons[index].values_
    bgColor = getBgColorFromFeature(feature)
    return bgColor;
}
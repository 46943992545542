// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import reportWebVitals from './reportWebVitals';
// //import App from "./App_one_map"
// import App from './App';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import './index.css';
import OpenLayersMapWebComponent from './OpenLayersMapWebComponent';

// Convertir a Web Component
customElements.define(
  'openlayers-map', 
  OpenLayersMapWebComponent
  //toWebComponent(OpenLayersMapWebComponent, React, ReactDOM)
);


